@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tiro-bangla-regular {
  font-family: "Tiro Bangla", serif;
  font-weight: 500;
  font-style: normal;
}

.tiro-bangla-regular-italic {
  font-family: "Tiro Bangla", serif;
  font-weight: 400;
  font-style: italic;
}


.default-box {
  /*display: flex;*/
  /* margin-left: var(--sidepaddingLeft-inner);
  margin-right: var(--sidepaddingLeft-inner); */
  /* margin-top: 2em; */
  padding: 0.75em;
  margin: 1.5rem;

  background: #f9f9f9;
  border: 1px solid #d1dbe6;
  border-radius: 6px;
  margin-bottom: 1em;
}
