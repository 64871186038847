.map-container {
  width: 100%;
  height: 100vh;
  height: 100svh;

}
/* 
@media screen and (max-width: 600px) {
  .map-container {
    height: 89vh;
  }
} */

/* @media screen and (max-width: 600px) {
  .map-container {
    left: 0px;
  }
} */

/* .own-location-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  top: 85%;
  left: calc(240px + 0.5 * (100% - 240px));
  padding: 0.5em;
  border-radius: 10px;
  transform: translate(-50%, 0%);
  height: 40px;
  width: 300px;
} */

/* @media screen and (max-width: 600px) {
  .own-location-btn {
    left: 50%;
  }
} */
/* 
.ui-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  top: 5%;
  left: 50%;
  padding: 0.5em;
  border-radius: 10px;
  transform: translate(-50%, 0%);
  background: white;
  width: 50vw;
  height: 40px;
} */

.dot {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
}
